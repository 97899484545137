import React from 'react'
import PropTypes from 'prop-types'
import { Table, Modal } from 'antd'
import numeral from 'numeral'

const snipPercent = val => val.match(/\((.*)\)/i)[1]

const columns = [
  {
    dataIndex: 'Team',
    key: 'Rank',
    fixed: 'left',
    render: (text, record, idx) => <span>{idx + 1}</span>,
  },
  {
    title: 'Team',
    dataIndex: 'Team',
    key: 'Team',
    fixed: 'left',
    render: text => (
      <div>
        <span style={{ fontWeight: 'bold' }}>{text}</span>
      </div>
    ),
  },
  {
    title: 'Overall',
    dataIndex: 'OverallRecord',
    key: 'OverallRecord',
    sorter: (a, b) =>
      numeral(snipPercent(a.OverallRecord)).value() - numeral(snipPercent(b.OverallRecord)).value(),
    render: text =>
      text.replace(
        `(${snipPercent(text)})`,
        `(${numeral(snipPercent(text))
          .format('0.000')
          .replace(/^0+/, '')
          .slice(0, 4)})`
      ),
    defaultSortOrder: 'descend',
  },
  {
    title: 'Hardpoint',
    dataIndex: 'HPRecord',
    key: 'HPRecord',
    sorter: (a, b) =>
      numeral(snipPercent(a.HPRecord)).value() - numeral(snipPercent(b.HPRecord)).value(),
    render: text =>
      text.replace(
        `(${snipPercent(text)})`,
        `(${numeral(snipPercent(text))
          .format('0.000')
          .replace(/^0+/, '')
          .slice(0, 4)})`
      ),
  },
  {
    title: 'SnD',
    dataIndex: 'SNDRecord',
    key: 'SNDRecord',
    sorter: (a, b) =>
      numeral(snipPercent(a.SNDRecord)).value() - numeral(snipPercent(b.SNDRecord)).value(),
    render: text =>
      text.replace(
        `(${snipPercent(text)})`,
        `(${numeral(snipPercent(text))
          .format('0.000')
          .replace(/^0+/, '')
          .slice(0, 4)})`
      ),
  },
  {
    title: 'Control',
    dataIndex: 'ControlRecord',
    key: 'ControlRecord',
    sorter: (a, b) =>
      numeral(snipPercent(a.ControlRecord)).value() - numeral(snipPercent(b.ControlRecord)).value(),
    render: text =>
      text.replace(
        `(${snipPercent(text)})`,
        `(${numeral(snipPercent(text))
          .format('0.000')
          .replace(/^0+/, '')
          .slice(0, 4)})`
      ),
  },
]

const striped = (record, idx) => (idx % 2 === 1 ? 'striped-leaderboard-row' : '')

const Players = ({ showKey, onCancel, tableData }) => {
  return (
    <div>
      <Table
        dataSource={tableData}
        columns={columns}
        size="small"
        pagination={{ pageSize: 28, size: 'default' }}
        rowClassName={striped}
        scroll={{ x: true }}
        rowKey={row => `${row.Team}`}
      />
      <Modal visible={showKey} title="Key" onCancel={onCancel} footer={false}>
        <ul>
          <li>
            <strong>Overall</strong> - Overall Win-Loss Record
          </li>
          <li>
            <strong>Hardpoint</strong> - Hardpoint Win-Loss Record
          </li>
          <li>
            <strong>SnD</strong> - Search and Destroy Win-Loss Record
          </li>
          <li>
            <strong>Control</strong> - Control Win-Loss Record
          </li>
        </ul>
      </Modal>
    </div>
  )
}

Players.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showKey: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default Players
