import React from 'react'
import PropTypes from 'prop-types'
import { Table, Modal } from 'antd'
import numeral from 'numeral'

const columns = [
  {
    dataIndex: 'Team',
    key: 'Rank',
    fixed: 'left',
    render: (text, record, idx) => <span>{idx + 1}</span>,
  },
  {
    title: 'Team',
    dataIndex: 'Team',
    key: 'Team',
    fixed: 'left',
    render: text => (
      <div>
        <span style={{ fontWeight: 'bold' }}>{text}</span>
      </div>
    ),
  },
  {
    title: <span style={{ marginLeft: '12px' }}>Overall aKD</span>,
    dataIndex: 'OverallaKD',
    key: 'OverallaKD',
    sorter: (a, b) => a.OverallaKD - b.OverallaKD,
    render: text => <span style={{ marginLeft: '12px' }}>{numeral(text).format('0.00')}</span>,
    defaultSortOrder: 'descend',
  },
  {
    title: 'Overall K/D',
    dataIndex: 'OverallKD',
    key: 'OverallKD',
    sorter: (a, b) => a.OverallKD - b.OverallKD,
    render: text => numeral(text).format('0.00'),
  },
  {
    title: 'Overall Maps',
    dataIndex: 'OverallMaps',
    key: 'OverallMaps',
    sorter: (a, b) => a.OverallMaps - b.OverallMaps,
  },
  {
    title: 'Respawn K/D',
    dataIndex: 'RespawnKD',
    key: 'RespawnKD',
    sorter: (a, b) => a.RespawnKD - b.RespawnKD,
    render: text => numeral(text).format('0.00'),
  },
  {
    title: 'Respawn Maps',
    dataIndex: 'RespawnMaps',
    key: 'RespawnMaps',
    sorter: (a, b) => a.RespawnMaps - b.RespawnMaps,
  },
  {
    title: 'SnD K/D',
    dataIndex: 'SnDKD',
    key: 'SnDKD',
    sorter: (a, b) => a.SnDKD - b.SnDKD,
    render: text => numeral(text).format('0.00'),
  },
  {
    title: 'SnD Maps',
    dataIndex: 'SnDMaps',
    key: 'SnDMaps',
    sorter: (a, b) => a.SnDMaps - b.SnDMaps,
  },
]

const striped = (record, idx) => (idx % 2 === 1 ? 'striped-leaderboard-row' : '')

const KillDeath = ({ showKey, onCancel, tableData }) => {
  return (
    <div>
      <Table
        dataSource={tableData}
        columns={columns}
        size="small"
        pagination={{ pageSize: 28, size: 'default' }}
        rowClassName={striped}
        scroll={{ x: true }}
        rowKey={row => `${row.Team}`}
      />
      <Modal visible={showKey} title="Key" onCancel={onCancel} footer={false}>
        <ul>
          <li>
            <strong>Overall aKD</strong> - adjusted Kill/Death Ratio for All Modes
          </li>
          <li>
            <strong>Overall K/D</strong> - Kill/Death Ratio for All Modes
          </li>
          <li>
            <strong>Overall Maps</strong> - All Game Mode Maps Played
          </li>
          <li>
            <strong>Respawn K/D</strong> - Kill/Death Ratio for Respawn Modes
          </li>
          <li>
            <strong>Respawn Maps</strong> - Respawn Game Mode Maps Played
          </li>
          <li>
            <strong>SnD K/D</strong> - Kill/Death Ratio for SnD Modes
          </li>
          <li>
            <strong>SnD Maps</strong> - SnD Game Mode Maps Played
          </li>
        </ul>
      </Modal>
    </div>
  )
}

KillDeath.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showKey: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default KillDeath
