import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, Modal } from 'antd'
import numeral from 'numeral'

import TeamName from '../teamname'

const striped = (record, idx) => (idx % 2 === 1 ? 'striped-leaderboard-row' : '')

class Overall extends Component {
  state = {
    offset: 0,
  }

  columns = [
    {
      dataIndex: 'Player',
      key: 'Rank',
      fixed: 'left',
      render: (text, record, idx) => {
        const { offset } = this.state
        return <span>{offset + idx + 1}</span>
      },
    },
    {
      title: 'Player',
      dataIndex: 'player',
      key: 'Player',
      sorter: (a, b) => {
        if (a.Player.toLowerCase() < b.Player.toLowerCase()) return -1
        if (a.Player.toLowerCase() > b.Player.toLowerCase()) return 1
        return 0
      },
      render: (text, record) => (
        <div>
          <span style={{ fontWeight: 'bold' }}>{record.Player}</span>
          <br />
          <TeamName>{record.Team}</TeamName>
        </div>
      ),
    },
    {
      title: 'aKD',
      dataIndex: 'aKD',
      key: 'aKD',
      sorter: (a, b) => numeral(a.aKD).value() - numeral(b.aKD).value(),
      render: text => numeral(text).format('0.00'),
      defaultSortOrder: 'descend',
    },
    {
      title: 'K/D',
      dataIndex: 'OverallKD',
      key: 'OverallKD',
      sorter: (a, b) => numeral(a.OverallKD).value() - numeral(b.OverallKD).value(),
      render: text => numeral(text).format('0.00'),
    },
    {
      title: 'Maps',
      dataIndex: 'OverallMaps',
      key: 'OverallMaps',
      sorter: (a, b) => numeral(a.OverallMaps).value() - numeral(b.OverallMaps).value(),
    },
  ]

  onChange = (page, pageSize) => this.setState({ offset: (page - 1) * pageSize || 0 })

  render() {
    const {
      props: { tableData, showKey, onCancel },
      onChange,
    } = this

    const columnsWithTeamsFilter = columns => {
      const players = tableData.map(row => [row.Team, row.Player])
      const teams = tableData.map(row => row.Team)
      const cols = columns.slice(0)
      const filters = Array.from(new Set(teams)).map(team => ({
        text: team,
        value: `${team}SubMenu`,
        children: players
          .reduce(
            (acc, [playerTeam, player]) => {
              if (team === playerTeam) {
                acc.push({ text: player, value: player })
              }

              return acc
            },
            [{ text: 'All', value: team }]
          )
          .sort((a, b) => {
            if (a.text === 'All') return -1
            if (b.text === 'All') return 1
            if (a.text.toLowerCase() < b.text.toLowerCase()) return -1
            if (a.text.toLowerCase() > b.text.toLowerCase()) return 1
            return 0
          }),
      }))

      cols[1].filters = filters.sort((a, b) => {
        if (a.text.toLowerCase() < b.text.toLowerCase()) return -1
        if (a.text.toLowerCase() > b.text.toLowerCase()) return 1
        return 0
      })

      cols[1].filters = filters
      cols[1].onFilter = (value, record) =>
        record.Team.indexOf(value) === 0 || record.Player.indexOf(value) === 0

      return cols
    }

    return (
      <div>
        <Table
          dataSource={tableData}
          columns={columnsWithTeamsFilter(this.columns)}
          size="small"
          pagination={{ pageSize: 20, size: 'default', onChange }}
          rowClassName={striped}
          scroll={{ x: true }}
          rowKey={row => `${row.Player}-${row.Team}`}
        />
        <Modal visible={showKey} title="Key" onCancel={onCancel} footer={false}>
          <ul>
            <li>
              <strong>Specialist</strong> - Most Used Specialist
            </li>
            <li>
              <strong>aKD</strong> - Adjusted Overall Kill/Death Ratio
            </li>
            <li>
              <strong>K/D</strong> - Kill/Death Ratio
            </li>
            <li>
              <strong>Maps</strong> - Maps Played
            </li>
          </ul>
        </Modal>
      </div>
    )
  }
}

Overall.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showKey: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
}

export default Overall
