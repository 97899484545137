import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from 'react-emotion'
import { Tabs } from 'antd'

import Paragraph from '../../../../components/paragraph'
import Layout from '../../../../components/layout'

import PLQPlayers from '../../../../components/tables/plq/players'
import PLQTeams from '../../../../components/tables/plq/teams'

const { TabPane } = Tabs

const LeaderboardContainer = styled.div`
  margin: 0 auto;

  @media screen and (min-width: 1024px) {
    padding: 50px;
    width: 1024px;
  }

  @media screen and (max-width: 1023px) {
    padding: 20px;
    width: 100%;
  }

  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-left
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-body-outer
    > .ant-table-body-inner
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-fixed-right
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-tbody > tr > td,
  .ant-table-small > .ant-table-content > .ant-table-header > table > .ant-table-thead > tr > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-body
    > table
    > .ant-table-thead
    > tr
    > th,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-header
    > table
    > .ant-table-tbody
    > tr
    > td,
  .ant-table-small
    > .ant-table-content
    > .ant-table-scroll
    > .ant-table-header
    > table
    > .ant-table-thead
    > tr
    > th {
    padding: 0px 20px;
  }
`

const isLeaderboardEnabled = true

const formatForTable = edges => {
  return edges.map(edge => {
    return edge.node
  })
}

const PLQLeaderboardPage = ({ data, location }) => (
  <Layout location={location}>
    <LeaderboardContainer>
      <h1>Leaderboards</h1>
      <h4>Pro League Qualifiers - Columbus</h4>
      {!isLeaderboardEnabled && (
        <Paragraph>This event has yet to begin! Please check back later.</Paragraph>
      )}
      {isLeaderboardEnabled && (
        <Tabs>
          <TabPane tab="Players" key="players">
            <PLQPlayers tableData={formatForTable(data.allLeaderboardPlayersPlqCsv.edges)} />
          </TabPane>
          <TabPane tab="Teams" key="teams">
            <PLQTeams tableData={formatForTable(data.allLeaderboardTeamsPlqCsv.edges)} />
          </TabPane>
        </Tabs>
      )}
    </LeaderboardContainer>
  </Layout>
)

PLQLeaderboardPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
}

export default PLQLeaderboardPage

export const query = graphql`
  query {
    allLeaderboardPlayersPlqCsv {
      edges {
        node {
          Player
          Team
          RespawnKD
          RespawnEng
          RespawnPTD
          RespawnMaps
          SnDKD
          SnDPTD
          SnDMaps
          HPKD
          HillTime
          HPMaps
          ControlKD
          CLU
          ControlMaps
          OverallKD
          aKD
          OverallMaps
        }
      }
    }
    allLeaderboardTeamsPlqCsv {
      edges {
        node {
          Team
          OverallRecord
          HPRecord
          SNDRecord
          ControlRecord
          RespawnKD
          RespawnMaps
          SnDKD
          SnDMaps
          OverallKD
          OverallaKD
          OverallMaps
        }
      }
    }
  }
`
